export const GlobalVariable = {

    SITE_NAME: 'Medical Exam',
    PATTERNS: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    tokenKey: 'medical_exam_admin',
    types: [
        {name: 'QUESTION BANK', id: 'QUESTION_BANK'},
        {name: 'OSCER', id: 'OSCER'},
        {name: 'CRASH COURSE', id: 'STUDY_MATERIAL'},
        {name: 'MEETING', id: 'MEETING'},
        {name: 'GENRIC', id: 'GENRIC'}
    ]

};
