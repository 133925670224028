import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RoutePartsService } from './services/router-parts.service';
import { SwUpdate } from '@angular/service-worker';
import { GlobalVariable } from './core/global';
import { PwaService } from './services/pwa.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    appTitle = GlobalVariable.SITE_NAME;
    pageTitle = '';

    constructor(private router: Router, private activeRoute: ActivatedRoute, private routePartsService: RoutePartsService,
                private title: Title, private swUpdate: SwUpdate, private pwaUpdate: PwaService
    ) {
        // this.checkNewVersion();
        // this.checkForUpdates()
        this.pwaUpdate.checkForUpdates();
    }

    ngOnInit() {
        this.changePageTitle();
    }

    changePageTitle() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
            const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length) {
                return this.title.setTitle(this.appTitle);
            }
            // Extract title from parts;
            this.pageTitle = routeParts.reverse().map((part) => part.title).reduce((partA, partI) => `${partA} > ${partI}`);
            this.pageTitle = `${this.appTitle} | ` + this.pageTitle;
            this.title.setTitle(this.pageTitle);
        });
    }

    // public checkForUpdates(): void {
    //   this.updateSubscription = this.swUpdate.available.subscribe(event => this.promptUser());
    //   if (this.swUpdate.isEnabled) {
    //     // Required to enable updates on Windows and ios.
    //     this.swUpdate.activateUpdate();
    //     interval(300000).subscribe(() => {
    //       this.swUpdate.checkForUpdate().then(() => {
    //       });
    //     });
    //   }
    // }

    // // Important: on Safari (ios) Heroku doesn't auto redirect links to their-
    // //https which allows the installation of the pwa like usual
    // // but it deactivates the swUpdate. So make sure to open your pwa on safari
    // //like so: https://example.com then (install/add to home)

    // promptUser(): void {
    //   this.swUpdate.activateUpdate().then(() => {
    //     if (confirm("New version available. Load New Version?")) {
    //       window.location.reload();
    //     }
    //   });
    // }

    // checkNewVersion() {
    //   if (this.swUpdate.isEnabled) {
    //     this.swUpdate.available.subscribe(() => {
    //       if (confirm("New version available. Load New Version?")) {
    //         window.location.reload();
    //       }
    //     });
    //   }
    // }

}
