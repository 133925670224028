import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  promptEvent;
  updateAvailabe = false;

  constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef) {
    window.addEventListener('beforeinstallprompt', (event) => {
      this.promptEvent = event;
    });
    this.checkUpdate();
  }

  checkForUpdates() {
    this.swUpdate.available.subscribe((event) => {
      this.promptUser();
    });
  }

  promptUser() {
    if (
      confirm(
        'This site has been updated with new data. Do you wish to reload the site to get the new data?'
      )
    ) {
      this.swUpdate.activateUpdate().then(() => document.location.reload());
    }
  }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);
        // const timeInterval = interval(20000);

        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => console.log('checked'));
        });
      }
    });
  }
}
