import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

// Directives
import { AmountDirective } from './directives/amount.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NoRecordComponent } from '../layout/common/no-record/no-record.component';
import { ReplaceUnderscorePipe } from './pipe/remove-underscore';
import { TagInputModule } from 'ngx-chips';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AddEditExamsComponent } from '../layout/exams/add-edit-exams/add-edit-exams.component';
import { HHMMSSPipe } from './pipe/hh-mm-ss.pipe';
import {
    AddEditQuestionBankComponent
} from '../layout/question-bank/add-edit-question-bank/add-edit-question-bank.component';
import { ReplacePipe } from './pipe/replace-text/replace-text.pipe';

const directives = [
    NumberOnlyDirective,
    AmountDirective,
    NoRecordComponent,
    ReplaceUnderscorePipe,
    AddEditExamsComponent,
    AddEditQuestionBankComponent,
    HHMMSSPipe,
    ReplacePipe

];

const imports = [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2TelInputModule,
    TagInputModule
];

@NgModule({
    declarations: [
        ...directives
    ],
    imports: [
        CommonModule,
        ...imports,
        NgMultiSelectDropDownModule.forRoot(),

        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ModalModule.forRoot()
    ],
    exports: [
        TagInputModule,
        ...directives,
        ...imports,
        BsDatepickerModule,
        TimepickerModule,
        ModalModule,
        NgMultiSelectDropDownModule
    ],
    entryComponents: [AddEditExamsComponent, AddEditQuestionBankComponent],
    providers: []
})
export class SharedModule {
}
