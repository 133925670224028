import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalVariable } from '../core/global';

@Injectable({
   providedIn: 'root'
})
export class UiService {

   constructor(private _location: Location) {
   }

   imageEmpty(id) {
      document.getElementById(id)[`value`] = '';
   }

   // localstorage setvalue, clear and get value start
   getToken() {
      if (!!localStorage.getItem(GlobalVariable.tokenKey)) {
         return localStorage.getItem(GlobalVariable.tokenKey);
      } else {
         return '';
      }
   }

   setToken(data) {
      localStorage.setItem(GlobalVariable.tokenKey, data);
   }

   setLocalData(key: string, data: any, json?: boolean) {
      localStorage.setItem(key, json ? JSON.stringify(data) : data);
   }

   getLocalData(key: string, json?: boolean) {
      let _data: any = null;
      try {
         _data = json
                ? JSON.parse(localStorage.getItem(key))
                : localStorage.getItem(key);
         return _data;
      } catch (error) {
         if (error instanceof SyntaxError) {
            this.clearLocalData(key);
         }
         return null;
      }
   }

   clearAllLocalData() {
      localStorage.clear();
   }

   clearLocalData(key: string) {
      localStorage.removeItem(key);
   }

   // localstorage setvalue, clear and get value end

   goBack() {
      this._location.back();
   }

   cal(page, limit, count) {
      if (page * limit <= count) {
         return page * limit;
      } else {
         return count;
      }
   }

   getTimeFromMinutes(time) {
      const start = new Date();
      start.setHours(0, time, 0, 0);
      return start;
   }

   makeLastDayHourMiliSeconds(data) {
      let day: any = new Date(data).getDate();
      day = day < 10 ? `0${day}` : day;
      const year = new Date(data).getFullYear();
      let month: any = new Date(data).getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      return new Date(`${month}-${day}-${year} 23:59:59`).getTime();
   }

   makeStartDayHourMiliSeconds(data) {
      let day: any = new Date(data).getDate();
      day = day < 10 ? `0${day}` : day;
      const year = new Date(data).getFullYear();
      let month: any = new Date(data).getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      return new Date(`${month}-${day}-${year} 00:00:00`).getTime();
   }

}
