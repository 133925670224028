export const ApiUrl = {

    login: '/Admin/Login',

    list_users: '/Admin/list_users',
    manage_users: '/Admin/manage_users',

    add_edit_languages: '/Admin/add_edit_languages',
    list_languages: '/Admin/list_languages',
    manage_languages: '/Admin/manage_languages',
    list_cdm_cases_exams: '/Admin/list_cdm_cases_exams',
    add_edit_university: '/Admin/add_edit_university',
    list_universities: '/Admin/list_universities',
    manage_university: '/Admin/manage_university',
    add_edit_banners: '/Admin/add_edit_banners',
    study_matiral_plans_details: '/Admin/study_matiral_plans_details',
    list_banners: '/Admin/list_banners',
    manage_banners: '/Admin/manage_banners',

    add_edit_oscer_categories: '/Admin/add_edit_oscer',
    list_oscer_categories: '/Admin/list_oscer',
    manage_oscer_categories: '/Admin/manage_oscer',

    add_edit_oscer_cases: '/Admin/add_edit_oscer_cases',
    list_oscer_cases: '/Admin/list_oscer_cases',
    manage_oscer_cases: '/Admin/manage_oscer_cases',
    manage_cdm_cases_exams: '/Admin/manage_cdm_cases_exams',
    manage_cdm_exams: '/Admin/manage_cdm_exams',

    add_edit_study_material: '/Admin/add_edit_study_material',
    manage_study_material: '/Admin/manage_study_material',
    list_study_material: '/Admin/list_study_material',

    add_edit_questions: '/Admin/add_edit_questions',
    manage_questions: '/Admin/manage_questions',
    list_questions: '/Admin/list_questions',

    list_settings: '/Admin/list_settings',
    add_edit_settings: '/Admin/add_edit_settings',

    add_edit_plans: '/Admin/add_edit_plans',
    list_plans: '/Admin/list_plans',
    manage_plans: '/Admin/manage_plans',

    add_edit_oscer_topics: '/Admin/add_edit_oscer_topics',
    list_oscer_topics: '/Admin/list_oscer_topics',
    manage_oscer_topics: '/Admin/manage_oscer_topics',

    add_edit_exams: '/Admin/add_edit_exams',
    list_exams: '/Admin/list_exams',
    manage_exams: '/Admin/manage_exams',

    add_edit_question_bank: '/Admin/add_edit_question_bank',
    list_question_bank: '/Admin/list_question_bank',
    manage_question_bank: '/Admin/manage_question_bank',
    list_attempted_exams: '/Admin/list_attempted_exams',

    add_edit_teachers: '/Admin/add_edit_teacher',
    list_teachers: '/Admin/list_teachers',
    manage_teachers: '/Admin/manage_teachers',

    forgotPassword: '/Admin/forgot_password',
    list_attempted_qa: '/Admin/list_attempted_qa',

    list_user_question_bank: '/Admin/list_user_qstn_bank',
    list_qstn_bank_participants: '/Admin/list_subscribed_qb',
    list_attempted_test: '/Admin/list_attempted_test',
    list_attempted_user_qa: '/Admin/list_user_qstn_bank',
    list_attempted_question_bank: '/Admin/list_atmptd_qstn_bank',
    teacher_join_meeting: '/Admin/join_meeting',
    teacher_manage_meeting: '/Admin/manage_meeting',
    teacher_end_call: '/Admin/end_call',
    teacher_getNotifications: '/Admin/list_notifications',
    teacher_list_users: '/Admin/list_users',
    create_meetings: '/Admin/create_meeting',
    upload_questions: '/Admin/upload_questions',
    add_excel_question: '/Admin/add_excel_question',
    upload_universities:'/Admin/upload_universities',
    list_teachers_meetings: '/Admin/list_meetings',
    list_promotion_code: '/Admin/list_promotion_code',
    add_edit_promotion_code: '/Admin/add_edit_promotion_code',
    manage_promotion_code: '/Admin/manage_promotion_code',
    managed_meeting: '/Admin/managed_meeting',
    manage_teachers_meetings: '/Admin/manage_meetings',
    oscer_plans_details: '/Admin/oscer_plans_details',
    add_edit_cmd_cases: '/Admin/add_edit_cmd_cases',
    add_edit_cdm_exam: '/Admin/add_edit_cdm_exam',
    choose_oscer_plan: '/Admin/choose_oscer_plan',
    choose_study_matiral_plan: '/Admin/choose_study_matiral_plan',
    list_cdm_exams: '/Admin/list_cdm_exams',
    // upload: 'https://dev-fileupload.mccqbank.com/Upload/postUploadFile_upload',
    upload: 'https://dev-fileupload.mccqbank.com/Upload/file_upload',
    // upload: 'https://fileupload.mccqbank.com/Upload/file_upload',
    list_tutorials:'/Admin/list_tutorials',
    add_edit_tutorials:'/Admin/add_edit_tutorials',
    manage_tutorials:'/Admin/manage_tutorials'

};
