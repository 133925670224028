import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MessageService } from 'src/app/services/message/message.service';
import { ApiUrl } from 'src/app/core/apiUrl';
import { HttpService } from 'src/app/services/http/http.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SuccessErrorConst } from 'src/app/core/successErrorConst';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-add-edit-exams',
    templateUrl: './add-edit-exams.component.html',
    styleUrls: ['./add-edit-exams.component.scss']
})
export class AddEditExamsComponent implements OnInit {

    showError = false;
    form: FormGroup;
    id = '';
    public onClose: Subject<{}>;
    allExams = [];
    overAllCases = [];
    typeData = [];
    allOscerTopics = [];
    allCategory = [];
    examType = '';
    allCases = [];

    constructor(private router: Router, private fb: FormBuilder, private message: MessageService, private http: HttpService,
                public bsRef: BsModalRef, private activatedRoute: ActivatedRoute) {

        this.activatedRoute.queryParams.subscribe(res => {
            if (res.type) {
                this.examType = res.type;
            }
        });

        console.log(activatedRoute, 'activatedRoute');

        if (!this.examType) {
            this.examType = this.router.url.indexOf('oscer') > -1 ? 'OSCER' : 'QUESTION_BANK';
            this.examType = 'OSCER';
        }
        console.log(this.examType, '        this.examType ', this.router.url.indexOf('OSCER'));
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.makeForm();
        if (this.examType === 'OSCER') {
            this.getCategory();
            this.getCase();
            // this.getOscerTopics();
        } else {
            this.getQuestionBanks();
        }
    }

    getCategory(patchData?) {
        const obj = {};
        this.http.getData(ApiUrl.list_oscer_categories, obj).subscribe(response => {
            this.allCategory = response.data.data;
            if (this.form.get('oscer_id')) {
                if (patchData) {
                    this.form.get('oscer_id').patchValue(patchData.oscer_id ? patchData.oscer_id._id ? patchData.oscer_id._id : patchData.oscer_id : '');
                    this.getCase(patchData);
                }
            }
        }, () => {
        });
    }

    getCase(patchData?) {
        const obj = {};
        this.http.getData(ApiUrl.list_oscer_cases, obj).subscribe(response => {
            this.overAllCases = response.data.data;
            if (this.form.get('oscer_case_id')) {
                if (patchData) {
                    this.allCases = this.overAllCases.filter(x => x.oscer_id._id === this.form.get('oscer_id').value);
                    this.form.get('oscer_case_id').patchValue(patchData.oscer_case_id ? patchData.oscer_case_id._id ? patchData.oscer_case_id._id : patchData.oscer_case_id : '');
                    // this.getOscerTopics(patchData);
                }
            }
        }, () => {
        });
    }

    // getOscerTopics(patchData?): void {
    //     this.http.getData(ApiUrl.list_oscer_topics, {}).subscribe(response => {
    //         this.allOscerTopics = response.data.data;
    //         if (this.form.get('oscer_topic_id')) {
    //             if (patchData) {
    //                 this.typeData = this.allOscerTopics.filter(x => x.oscer_case_id._id === this.form.get('oscer_case_id').value);
    //                 this.form.get('oscer_topic_id').patchValue(patchData.oscer_topic_id ? patchData.oscer_topic_id._id ? patchData.oscer_topic_id._id : patchData.oscer_topic_id : '');
    //             }
    //         }
    //     }, () => {
    //     });
    // }

    getQuestionBanks(patchData?): void {
        this.http.getData(ApiUrl.list_question_bank, {}).subscribe(response => {
            this.typeData = response.data.data;
            if (patchData) {
                this.form.get('qstn_bank_id').patchValue(patchData.qstn_bank_id ? patchData.qstn_bank_id._id : '');
            }
        }, () => {
        });
    }

    onSelectCategory(e): void {
        this.form.get('oscer_case_id').patchValue('');
        // this.form.get('oscer_topic_id').patchValue('');
        this.allCases = this.overAllCases.filter(x => x.oscer_id._id === e.target.value);
    }

    onSelectCase(e): void {
        // this.form.get('oscer_topic_id').patchValue('');
        // this.typeData = this.allOscerTopics.filter(x => x.oscer_case_id._id === e.target.value);
    }

    makeForm() {
        this.form = this.fb.group({
            name: new FormArray([]),
            type: [this.examType, Validators.required]
        });

        this.addControls();
        this.addName('ENGLISH');
        this.addName('FRENCH');
    }

    addControls(): void {
        if (this.examType === 'OSCER') {

            console.log('OSCER');
            this.form.addControl('oscer_id', new FormControl('', [Validators.required]));
            this.form.addControl('oscer_case_id', new FormControl('', [Validators.required]));
            // this.form.addControl('oscer_topic_id', new FormControl('', [Validators.required]));
            this.form.addControl('topic_type', new FormControl('', [Validators.required]));
            this.form.removeControl('qstn_bank_id');
            this.form.removeControl('total_marks');
            this.form.removeControl('passing_marks');
            // this.form.removeControl('duration');
            console.log(this.form, 'OSCEROSCER');
        }

        if (this.examType === 'QUESTION_BANK') {

            console.log('QUESTION_BANK');

            this.form.addControl('qstn_bank_id', new FormControl('', [Validators.required]));
            // this.form.addControl('duration', new FormControl('', [Validators.required]));
            this.form.addControl('total_marks', new FormControl('', [Validators.required]));
            this.form.addControl('passing_marks', new FormControl('', [Validators.required]));
            this.form.removeControl('topic_type');
            this.form.removeControl('oscer_id');
            this.form.removeControl('oscer_case_id');
            // this.form.removeControl('oscer_topic_id');
        }
    }

    addName(type) {
        const self = this;
        const control = self.form.controls.name as FormArray;
        control.push(self.addNameArray(type));
    }

    addNameArray(type) {
        return this.fb.group({
            language_id: [type, Validators.required],
            name: ['', Validators.required]
        });
    }

    patchValue(data) {
        this.id = data._id;
        this.examType = data.type;
        this.addControls();
        if (this.examType === 'OSCER') {
            if (data.linkQuestion) {
                // this.form.addControl('duration', new FormControl(data.duration, [Validators.required]));
                this.form.addControl('total_marks', new FormControl(data.total_marks, [Validators.required]));
                this.form.addControl('question_ids', new FormControl(data.question_ids, [Validators.required]));
                // this.form.addControl('passing_marks', new FormControl(data.passing_marks, [Validators.required]));
                // this.form.addControl('grace_time', new FormControl(data.grace_time ? data.grace_time : 0));
            }
            this.getCategory(data);
            this.form.get('topic_type').patchValue(data.topic_type ? data.topic_type : '');
        } else {
            this.getQuestionBanks(data);
            this.form.get('total_marks').patchValue(data.total_marks ? data.total_marks : '');
            this.form.get('passing_marks').patchValue(data.passing_marks ? data.passing_marks : '');
            // this.form.get('duration').patchValue(data.duration ? data.duration : '');
        }

        this.form.patchValue({
            type: data.type,
            name: data.name
        });
    }

    addEdit() {

        console.log(this.form, 'Oscer Organ SystemOscer Organ SystemOscer Organ System');

        if (this.form.valid) {
            const obj: any = JSON.parse(JSON.stringify(this.form.value));
            // if (!obj.grace_time) {
            //     obj.grace_time = 0;
            // }
            if (obj.passing_marks && obj.total_marks && obj.passing_marks > obj.total_marks) {
                this.message.toast('error', SuccessErrorConst.marksError);
                return;
            }
            if (this.id) {
                obj._id = this.id;
            }
            this.addServer(obj);
        } else {
            this.form.markAllAsTouched();
        }
    }

    addServer(obj) {
        this.http.postData(ApiUrl.add_edit_exams, obj).subscribe(() => {
            this.onClose.next();
            if (this.id) {
                this.message.toast('success', SuccessErrorConst.updatedSuccess);
            } else {
                this.message.toast('success', SuccessErrorConst.addedSuccess);
                this.blocked(obj.oscer_id);
            }
            this.bsRef.hide();
            if (this.examType === 'OSCER') {
                this.router.navigate(['/oscer-exams']);
            } else {
                this.router.navigate(['/question-bank-exams']);
            }
        }, () => {
        });
    }

    blocked(id) {
        const obj = {
            _id: id,
            is_blocked: false
        };
        this.http.putData(ApiUrl.manage_oscer_categories, obj).subscribe(res => {
            // this.message.toast('success', SuccessErrorConst.updatedSuccess);
        });
    }
}
