import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MessageService } from 'src/app/services/message/message.service';
import { ApiUrl } from 'src/app/core/apiUrl';
import { HttpService } from 'src/app/services/http/http.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SuccessErrorConst } from 'src/app/core/successErrorConst';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../../services/ui.service';

@Component({
    selector: 'app-add-edit-question-bank',
    templateUrl: './add-edit-question-bank.component.html',
    styleUrls: ['./add-edit-question-bank.component.scss']
})
export class AddEditQuestionBankComponent implements OnInit {

    form: FormGroup;
    id = '';
    public onClose: Subject<{}>;
    allQuestions = [];
    dropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'myName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    plans = [];
    teachers: any = [];
    studies: any = [];
    modalData: any = {};
    teacher_id = new FormControl([]);
    study_material_id = new FormControl([]);

    constructor(private fb: FormBuilder, private message: MessageService, private http: HttpService,
                public bsModalRef: BsModalRef, public router: Router,
                public activatedRoute: ActivatedRoute, public uiService: UiService) {
        this.makeForm();

    }

    getTeacherList() {
        this.http.getData(ApiUrl.list_teachers).subscribe(response => {
            this.teachers = response.data.data;
            response.data.data.forEach((val) => {
                val.myName = val.name[0].name;
                if (this.modalData) {
                    const tempArr: any = [];
                    if (this.modalData.teacher_id.includes(val._id)) {
                        tempArr.push(val);
                        this.teacher_id.patchValue(tempArr);
                    }
                }
            });

        });
    }

    getStudyList() {
        this.http.getData(ApiUrl.list_study_material).subscribe(response => {
            this.studies = response.data.data;
            response.data.data.forEach((val) => {
                val.myName = val.name[0].name;
                if (this.modalData) {
                    const tempArr: any = [];
                    if (this.modalData.study_material_id.includes(val._id)) {
                        tempArr.push(val);
                        this.study_material_id.patchValue(tempArr);
                    }
                }
            });

        });
    }

    planTypeChanged() {
        if (this.form.value.plan_type === 'plan') {
            this.form.controls.plan_id.setValidators([Validators.required]);
        } else {
            this.form.controls.plan_id.clearValidators();
            this.form.controls.plan_id.setValue('');
        }
        this.form.controls.plan_id.updateValueAndValidity();
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.getPlans();
        this.getTeacherList();
        this.getStudyList();

    }

    getPlans(): void {
        this.http.getData(ApiUrl.list_plans, {type: 'QUESTION_BANK'}).subscribe(response => {
            this.plans = response.data.data;
        });
    }

    makeForm() {
        this.form = this.fb.group({
            title: new FormArray([]),
            description: new FormArray([]),
            tags: ['ADMIN', Validators.required],
            // type: ['', Validators.required],
            is_meeting_linked: [true, Validators.required],
            // special_key: ['', Validators.required],
            min_percentage: [0],
            keywords: ['', Validators.required],
            other_user_percentage: [null, Validators.required],
            mode: [''],
            can_create_test: ['', Validators.required],
            can_create_study_plan: ['', Validators.required],
            category: [''],
            can_attempt_test: [''],
            attempt_test_type: [''],
            // plan_type: [''],
            plan_id: ['']
        });
        this.addTitle('ENGLISH');
        this.addTitle('FRENCH');
        this.addDescription('ENGLISH');
        this.addDescription('FRENCH');
    }

    addTitle(type) {
        const self = this;
        const control = self.form.controls.title as FormArray;
        control.push(self.addTitleArray(type));
    }

    addDescription(type) {
        const self = this;
        const control = self.form.controls.description as FormArray;
        control.push(self.addDescriptionArray(type));
    }

    addTitleArray(type) {
        return this.fb.group({
            language_id: [type, Validators.required],
            title: ['', Validators.required]
        });
    }

    addDescriptionArray(type) {
        return this.fb.group({
            language_id: [type, Validators.required],
            description: ['', Validators.required]
        });
    }

    patchValue(data) {
        this.id = data._id;
        this.form.patchValue({
            category: data.category,
            title: data.title,
            // type: data.type,
            description: data.description,
            tags: data.tags ? data.tags : 'ADMIN',
            min_percentage: data.min_percentage,
            // special_key: data.special_key,
            is_meeting_linked: data.is_meeting_linked,
            keywords: data.keywords,
            other_user_percentage: data.other_user_percentage,
            plan_id: typeof data.plan_id === 'string' ? data.plan_id : data.plan_id ? data.plan_id._id : '',
            mode: data.mode,
            can_attempt_test: data.can_attempt_test,
            can_create_test: data.can_create_test === 'true' || data.can_create_test === true ? 'true' : 'false',
            can_create_study_plan: data.can_create_study_plan === 'true' || data.can_create_study_plan === true ? 'true' : 'false',
            attempt_test_type: data.attempt_test_type
        });

        if (data.plan_id) {
            // this.form.controls.plan_type.patchValue('plan');
            this.form.controls.plan_id.patchValue(data.plan_id._id || data.plan_id);
        } else {
            // this.form.controls.plan_type.patchValue('free');
        }

        const words = [];

        // if (data.time_duration) {
        if (data.mode === 'TIME_MODE' || data.mode === 'BOTH') {
            this.form.addControl('time_duration', new FormControl(data.time_duration, [Validators.required]));
        }

        if (data.question_ids.length) {
            this.form.addControl('question_ids', new FormControl(data.question_ids, [Validators.required]));
            // this.form.addControl('grace_time', new FormControl(data.grace_time ? data.grace_time : 0));
            this.form.addControl('passing_marks', new FormControl(data.passing_marks, [Validators.required]));
        }

        if (data.keywords.length) {
            data.keywords.forEach(element => {
                words.push({display: element, value: element});
            });
        }

        this.form.get('keywords').patchValue(words);
    }

    addEdit() {
        if (this.form.valid) {
            const obj = JSON.parse(JSON.stringify(this.form.value));
            if (this.form.value.is_meeting_linked === 'true' || this.form.value.is_meeting_linked === true) {
                const tempArr: any = [];
                if (this.teacher_id.value && this.teacher_id.value) {
                    this.teacher_id.value.forEach((val) => {
                        tempArr.push(val._id);
                    });
                    obj.teacher_id = tempArr;
                } else {
                    this.message.toast('error', 'Please select atleast 1 teacher');
                    return;
                }

                const tempArr2: any = [];
                if (this.study_material_id.value && this.study_material_id.value) {
                    this.study_material_id.value.forEach((val) => {
                        tempArr2.push(val._id);
                    });
                    obj.study_material_id = tempArr2;
                } else {
                    this.message.toast('error', 'Please select atleast 1 Crash Course');
                    return;
                }
            }

            // if (!obj.grace_time) {
            //     obj.grace_time = 0;
            // }

            if (this.id) {
                obj._id = this.id;
            }
            const words = [];
            obj.keywords.forEach(element => {
                words.push(element.value);
            });
            obj.keywords = words;

            // if (obj.plan_type === 'free') {
            //     delete obj.plan_id;
            // }

            // obj.min_percentage = parseInt(obj.min_percentage, 10);

            delete obj.min_percentage;
            // delete obj.plan_type;
            this.addServer(obj);
        } else {
            this.form.markAllAsTouched();
        }
    }

    addServer(obj) {
        this.http.postData(ApiUrl.add_edit_question_bank, obj).subscribe(response => {
            this.onClose.next();
            if (this.id) {
                this.message.toast('success', SuccessErrorConst.updatedSuccess);
            } else {
                this.message.toast('success', SuccessErrorConst.addedSuccess);
            }
            this.bsModalRef.hide();
            if (this.modalData.linkQuestion) {
                this.uiService.goBack();
            }
            // link-questions/question-bank

        }, () => {
        });
    }

}
