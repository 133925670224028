export const SuccessErrorConst = {

   loginAgin: 'Please login Again!',
   pleaseFillRequiredData: 'Please fill all the required data!',
   addedSuccess: 'Added Successfully!',
   updatedSuccess: 'Updated Successfully!',
   addBadge: 'Fistly Add Badge!',
   deleteSuccess: 'Deleted Successfully!',
   marksError: 'Passing Marks should be less than total marks',
   rejectedSuccess: 'Rejected Successfully!',
   endedSuccess: 'Ended Successfully!',
   messageSuccess: 'Message sent successfully!',
   forgotPasswordMessage: 'Reset Passowrd Link has been sent to your email.',
   addOptionsError: 'Error : Please add atleast 2 options'

};
